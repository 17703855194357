// scss-docs-start alert-Radzen.Variant-mixin
@mixin alert-Radzen.Variant($background, $border, $color) {
  color: $color;
  @include gradient-bg($background);
  border-color: $border;

  .alert-link {
    color: shade-color($color, 20%);
  }
}
// scss-docs-end alert-Radzen.Variant-mixin
